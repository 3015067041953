import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 5
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    color: '#20466b',
  },
  link: {
    '&:hover': {
      color: 'rgba(44,70,107,1)',
      '-webkit-text-stroke': 'initial',
      '-webkit-text-stroke-color': 'rgba(44,70,107,1)',
      '-webkit-text-stroke-width': 0,
    }
  },

  // mainTitle: {
  //   height: 64,
  //   textAlign: 'left',
  // },
  pos: {
    marginBottom: 12,
  },

  cardContent: {
    flex: '1 0 auto',
  }
});

export default function SimpleCard(props) {
  const { t } = useI18next();

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (

    <Card className={classes.root} >
      <Link to={props.link} className={classes.link}>
        <CardContent className={classes.cardContent}>
          <Typography className="card-title" variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography className={"card-text"} variant="body2" component="p">
            {props.body}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button className="card-button" size="small">{t("LEARN-MORE")}</Button>
        </CardActions>
      </Link>
    </Card>


  );
}